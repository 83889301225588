'use client';

import { assetPrefix } from '@ahm/api-wrappers-core';
import { Button } from '@ahm/ui/components/ui/button';
import { zodResolver } from '@hookform/resolvers/zod';
import { Loader2 } from 'lucide-react';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import * as z from 'zod';
import { useAcquireMerchant } from '../api/use-acquire-merchant';
import SubmittedDialog from './submitted-dialog';
import useGoogleRecaptcha from './use-google-recaptcha';

const schema = z.object({
  name: z.string().min(1, { message: 'Họ và tên là bắt buộc' }),
  email: z.string().email({ message: 'Email không hợp lệ' }),
  phone_number: z
    .string()
    .regex(/^\d{10}$/, { message: 'Số điện thoại không hợp lệ' }),
});

export default function ContactForm() {
  const { loadGoogleReCaptchaScript, submitCaptcha } = useGoogleRecaptcha();
  const [opened, setOpened] = useState(false);
  const { mutate: mutateAcquireMerchant, status } = useAcquireMerchant({
    onSuccess() {
      reset;
      setOpened(true);
    },
    onError() {
      toast.error(
        'Không thể gửi thông tin ở thời điểm này, vui lòng thử lại sau.'
      );
    },
  });

  useEffect(() => {
    loadGoogleReCaptchaScript();
  }, [loadGoogleReCaptchaScript]);

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  });

  const onSubmitHandler = (data) => {
    const captcha = submitCaptcha();
    void captcha.then((token) => {
      const payload = {
        ...data,
        g_recaptcha_response: token,
      };
      mutateAcquireMerchant(payload);
    });
  };

  return (
    <>
      <SubmittedDialog open={opened} onClose={() => setOpened(false)} />
      <section className="bg-secondary-90">
        <div className="mx-auto max-w-screen-xl">
          <div className="grid w-full grid-cols-1 md:grid-cols-2">
            <div className="relative col-span-1 pb-12 pr-10 md:pb-16">
              <div className="relative -mt-16 aspect-[629/874] w-full pr-16 md:-mt-20">
                <Image
                  src={`${assetPrefix}/static/images/hi-ahafood.webp`}
                  alt={'chat bot image'}
                  fill
                  className="object-contain"
                />
              </div>
            </div>
            <div className="bg-neutral-10 col-span-1 space-y-10 px-6 py-20 md:px-8 md:py-24">
              <div className="mx-auto max-w-lg space-y-4">
                <h2 className="text-secondary-60 text-2xl font-bold md:text-[42px] md:leading-[52px]">
                  Liên hệ ngay với chúng tôi
                </h2>
                {/* <p className="font-semibold text-neutral-50">
                  Send review please, it will help us to be better!
                </p> */}
              </div>
              <form
                onSubmit={handleSubmit(onSubmitHandler)}
                className="mx-auto max-w-lg space-y-6"
              >
                <div className="space-y-2">
                  <label
                    htmlFor="name"
                    className="text-neutral-80 block text-sm font-medium"
                  >
                    Họ và tên
                  </label>
                  <input
                    {...register('name')}
                    id="name"
                    autoComplete="name"
                    type="text"
                    placeholder="Nhập họ và tên của bạn"
                    className="border-neutral-30 focus:border-primary-50 focus:ring-primary-50 block w-full rounded-sm border bg-white px-3 py-2 shadow-sm focus:outline-none"
                  />
                  {errors.name ? (
                    <p className="mt-1 text-sm text-red-50">
                      {typeof errors.name.message === 'string'
                        ? errors.name.message
                        : ''}
                    </p>
                  ) : null}
                </div>
                <div className="space-y-2">
                  <label
                    htmlFor="email"
                    className="text-neutral-80 block text-sm font-medium"
                  >
                    Email
                  </label>
                  <input
                    {...register('email')}
                    id="email"
                    autoComplete="email"
                    type="email"
                    placeholder="Nhập email của bạn"
                    className="border-neutral-30 focus:border-primary-50 focus:ring-primary-50 block w-full rounded-sm border bg-white px-3 py-2 shadow-sm focus:outline-none"
                  />
                  {errors.email && (
                    <p className="mt-1 text-sm text-red-50">
                      {typeof errors.email.message === 'string'
                        ? errors.email.message
                        : ''}
                    </p>
                  )}
                </div>
                <div className="space-y-2">
                  <label
                    htmlFor="phone"
                    className="text-neutral-80 block text-sm font-medium"
                  >
                    Số điện thoại
                  </label>
                  <input
                    {...register('phone_number')}
                    id="phone"
                    autoComplete="tel"
                    type="tel"
                    placeholder="Nhập số điện thoại của bạn"
                    className="border-neutral-30 focus:border-primary-50 focus:ring-primary-50 block w-full rounded-sm border bg-white px-3 py-2 shadow-sm focus:outline-none"
                  />
                  {errors.phone_number && (
                    <p className="mt-1 text-sm text-red-50">
                      {typeof errors.phone_number.message === 'string'
                        ? errors.phone_number.message
                        : ''}
                    </p>
                  )}
                </div>
                <Button
                  disabled={status === 'pending'}
                  type="submit"
                  className="bg-primary-50 h-[60px] w-full rounded px-12 py-4 text-xl font-bold text-white"
                >
                  {status === 'pending' ? (
                    <Loader2 size={20} className="mr-2 animate-spin" />
                  ) : null}
                  Gửi thông tin
                </Button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
