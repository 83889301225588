import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/ahafood/src/app/[locale]/(main)/components/contact-form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/ahafood/src/components/account-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["ScrollArea"] */ "/app/packages/ui/src/components/ui/scroll-area.tsx");
