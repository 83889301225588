import lottie, { type AnimationItem } from 'lottie-web';
import React, { useEffect, useRef } from 'react';

interface LottieAnimationProps {
  animationData: object; // The JSON Lottie file
  loop?: boolean; // Whether the animation should loop
  autoplay?: boolean; // Whether the animation should start automatically
  height?: string; // Height of the container
  width?: string; // Width of the container
}

const LottieAnimation: React.FC<LottieAnimationProps> = ({
  animationData,
  loop = false,
  autoplay = true,
  height = '100%',
  width = '100%',
}) => {
  const animationContainer = useRef<HTMLDivElement | null>(null);
  const animationInstance = useRef<AnimationItem | null>(null);

  useEffect(() => {
    if (animationContainer.current) {
      // Initialize the Lottie animation
      animationInstance.current = lottie.loadAnimation({
        container: animationContainer.current,
        renderer: 'svg', // 'svg' is the default renderer for Lottie
        loop,
        autoplay,
        animationData,
      });
    }

    // Clean up on component unmount
    return () => {
      if (animationInstance.current) {
        animationInstance.current.destroy();
      }
    };
  }, [animationData, loop, autoplay]);

  return <div ref={animationContainer} style={{ height, width }} />;
};

export default LottieAnimation;
